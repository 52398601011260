<template>
  <div class="nav-bar">
    <a-breadcrumb>
      <a-breadcrumb-item v-for="item in nav_bar_list" :key="item">
        {{ $t(`menu.${item}`) }}
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
import { Breadcrumb } from 'ant-design-vue'
export default {
  name: 'navBar',
  components: {
    'a-breadcrumb': Breadcrumb,
    'a-breadcrumb-item': Breadcrumb.Item,
  },
  props: ['name'],
  computed: {
    nav_bar_list() {
      let nav_bar_list = []
      let pathArr = this.$route.path
        .split('?')[0]
        .split('/')
        .filter((item) => item)
      nav_bar_list = ['orderManage', pathArr[0]]
      return nav_bar_list
    },
  },
}
</script>

<style scoped>
.nav-bar {
  /* margin: 4px 0 6px 15px; */
  margin: 0 0 4px 0;
}
</style>
<style>
.ant-breadcrumb-link {
  font-size: 14px;
  font-weight: 900;
}
</style>
