const baseURL = "https://oms.geezdata.com/";
const aftersaleURL = "https://aftersale.shopspade.com/api/";
const regionURL = "https://region.shopspade.com/api/";
const inventoryURL = "https://inventory.geezdata.com/api/";
const reportURL = "https://task.shopspade.com/api/";
const orderURL = "https://order.geezdata.com/api/";
const plymouthURL = "https://plymouth.geezdata.com/api/";
const idaasApi = "https://idaas.shopspade.com/api/";
const goodsApi = "https://product.geezdata.com/api/";
const shopApi = "https://shop.shopspade.com/api/";

const fileHost = "https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER";
const frontURL = "https://oms.geezdata.com";
const idaasURL = "https://idaas.shopspade.com/oauth";
const redirectUri = "https://oms.geezdata.com/oms_api/oauth/lazada";
const inventoryWebEntry = "https://inventory.geezdata.com";
const reportWebEntry = "https://task.shopspade.com";
const biErpWebEntry = "https://bi.shopspade.com";
const scmURL = "https://scm.shopspade.com";
const aftersaleWebEntry = "https://aftersale.shopspade.com";
const workorderWebEntry = "https://workorder.shopspade.com";
const scmWebEntry = "https://scm.shopspade.com";
const wmshubWebEntry = "https://wmshub.shopspade.com";
const campaignWebEntry = "https://campaign.shopspade.com";
const prepaymentEntry = "https://prepayment.shopspade.com";
const bestsellerEntry = "https://bestsellers.shopspade.com";
const shopWebEntry = "https://shop.shopspade.com";
const strategyWebEntry = "https://strategy.shopspade.com"; //策略中心
const goodsWebEntry = "https://product.shopspade.com"; //商品

const wsURL = 'wss://plymouth.geezdata.com/wsapi/'

export {
  aftersaleURL,
  regionURL,
  inventoryURL,
  reportURL,
  orderURL,
  plymouthURL,
  idaasApi,
  goodsApi,
  shopApi,
  fileHost,
  frontURL,
  baseURL,
  idaasURL,
  redirectUri,
  inventoryWebEntry,
  reportWebEntry,
  biErpWebEntry,
  scmURL,
  aftersaleWebEntry,
  workorderWebEntry,
  scmWebEntry,
  wmshubWebEntry,
  campaignWebEntry,
  prepaymentEntry,
  bestsellerEntry,
  shopWebEntry,
  strategyWebEntry,
  goodsWebEntry,
  wsURL
};
