// 原始订单
const originalOrders = () => import('./view/orderManage/originalOrders.vue')
const orderEntry = () => import('./view/orderManage/orderEntry.vue') //订单录入
// oms订单
const saleOrders = () => import('./view/orderManage/saleOrders.vue')
// 批量审单
const batchOrderReview = () => import('./view/orderManage/batchOrderReview.vue')
// 新建批量任务
const createBatchCheckOrderJob = () => import('./view/orderManage/createBatchCheckOrderJob.vue')
//---------------------------系统管理
//销售
const orderDetails = () => import('./view/orderManage/omsOrderDetail/orderDetails.vue')
const orderSplit = () => import('./view/orderManage/omsOrderDetail/orderSplit.vue')

let routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/saleOrders',
    component: saleOrders, // 销售订单管理
  },
  //orderManage
  {
    path: '/saleOrders',
    name: 'saleOrders',
    component: saleOrders, // 销售订单管理
  },
  {
    path: '/originalOrders',
    name: 'originalOrders',
    component: originalOrders, // 原始订单列表
  },
  {
    path: '/orderEntry',
    name: 'orderEntry',
    component: orderEntry, // 订单录入
  },
  {
    path: '/omsOrderDetail/orderDetails/:orderId?',
    name: 'orderDetails',
    component: orderDetails, // OMS订单详情页
  },
  {
    path: '/omsOrderDetail/orderSplit/:actionType/:orderId',
    name: 'orderSplit',
    component: orderSplit, // 拆单页面
  },
  {
    path: '/batchOrderReview',
    name: 'batchOrderReview',
    component: batchOrderReview, // 批量审单任务
  },
  {
    path: '/createBatchCheckOrderJob',
    name: 'createBatchCheckOrderJob',
    component: createBatchCheckOrderJob, // 新建批量任务
  },
  {
    path: '/404',
    name: '404',
    component: () => import('./view/common/404'),
  },
  {
    path: '/403',
    name: '403',
    component: () => import('./view/common/403'),
  },
  // 此处需特别注意至于最底部
  {
    path: '*',
    redirect: '/404',
  },
]

export default routes
