import constants from './common/constants'
import './common/common'
import './public-path'
import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入bootstrap
import App from './App.vue' // 根组件
import routes from './router.js'
import store from './store'
import { isEmptyObject, toThousandAmount } from './common'
import jsSHA from 'jssha'
import './css/index.scss'
import './utils/antd'
import './lib/layer/layer'
import './lib/layer/skin/layer.css'
import breadcrumb from '@component/breadcrumb/index'
import { message } from 'ant-design-vue'
import i18n from './utils/lang'
import actions from './qiankun/actions'

Vue.prototype.$jsSHA = jsSHA
Vue.component('breadcrumb', breadcrumb)

Vue.prototype.global = {}
Vue.prototype.constants = constants

Vue.prototype.global.currentSession = null
Vue.prototype.global.currentModule = null

Vue.prototype.showShop = true

Vue.prototype.$isEmptyObject = isEmptyObject
Vue.prototype.toThousandAmount = toThousandAmount

Vue.prototype.layer = {
  empty: () => {
    //搜索为空
    // layer.msg(`<span style='color:red'>${i18n.t('暂无数据')}~</span>`, {
    //   icon: 5,
    // })
    // message.success(i18n.t('操作成功'))
  },
  success: () => {
    //操作成功
    message.success(i18n.t('操作成功'))
  },
  selectAnOrder: () => {
    //选择一个订单
    message.warn(i18n.t('请选择订单'))
  },
}

Vue.prototype.$bus = new Vue()

let router = null
let instance = null
function render(props = {}) {
  const { container, priv, addPane } = props
  Vue.prototype.$addPane = addPane
  if (props) {
    // 注入 actions 实例
    actions.setActions(props)
  }
  router = new VueRouter({
    // 根据全局参数判断自己是作为第几层应用运行，加上对应的前缀，其实这里的前缀也可以在上层应用配置对应的全局参数来传递
    base: window.__POWERED_BY_QIANKUN_PARENT__
      ? `/${window.__QIANKUN_PARENTNAME__}/orderManage`
      : window.__POWERED_BY_QIANKUN__
      ? '/orderManage'
      : '/',
    mode: 'history',
    routes,
  })

  Vue.use(VueRouter)
  instance = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app')

  Vue.prototype.priv = function (name) {
    return priv(name) || (() => true)
  }
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap() {
  console.log('[vue2] order app bootstraped')
}

export async function mount(props) {
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  router = null
}
